@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Arimo:300,400,400italic,700,700italic);

* {
  margin: 0px;
  padding: 0px;
}

a {
  text-decoration: none;
  outline: none;
  color: #444;
}

a:hover {
  color: #444;
}

a:hover,
a:focus,
input,
textarea {
  text-decoration: none;
  outline: none;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

html,
body {
  height: 100%;
}

p {
  margin-bottom: 0px;
  width: 100%;
}

.no-padding {
  padding: 0px;
}

.no-margin {
  margin: 0px;
}

.hid {
  display: none;
}

.top-mar {
  margin-top: 15px;
}

.h-100 {
  height: 100%;
}

::placeholder {
  color: #747f8a !important;
  font-size: 13px;
  opacity: 0.5 !important;
}

.container-fluid {
  padding: 0px;
}

/* h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', sans-serif;
  color: #fff;
} */

strong {
  font-family: 'Open Sans', sans-serif;
}

form-login-body {
  background-color: #f1f1f145;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  color: #6a6a6a;
  overflow-x: hidden;
  max-height: 100% !important;
}

.session-title {
  padding: 30px;
  margin: 0px;
  margin-bottom: 20px;
}
.session-title h2 {
  width: 100%;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}
.session-title p {
  max-width: 850px;
  text-align: center;
  float: none;
  margin: auto;
  font-size: 0.83rem;
  margin-top: 15px;
}
.session-title span {
  float: right;
  font-style: italic;
}

.inner-title {
  padding: 20px;
  padding-left: 0px;
  margin: 0px;
  margin-bottom: 10px;
  padding-bottom: 0px;
  border-bottom: 1px solid #ccccccc4;
  display: block;
  padding-right: 0px;
}
.inner-title h2 {
  width: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  text-align: left;
  border-bottom: 1px solid #863dd9;
  padding-bottom: 10px;
  margin-bottom: 0px;
  width: 300px;
}
.inner-title p {
  width: 100%;
  text-align: center;
}
.inner-title .btn {
  float: right;

  font-weight: 700;
  font-size: 2rem;
}

.page-nav {
  background-size: 100%;
  padding: 68px;
  text-align: center;
  background-color: #eaeaea;
}
.page-nav ul {
  float: none;
  margin: auto;
}
@media screen and (max-width: 992px) {
  .page-nav {
    background-size: auto;
  }
}
@media screen and (max-width: 767px) {
  .page-nav {
    padding-top: 100px;
  }
}
@media screen and (max-width: 356px) {
  .page-nav {
    padding-top: 200px;
  }
}
.page-nav h2 {
  font-size: 28px;
  width: 100%;
  font-weight: 700;
  color: #444;
}
@media screen and (max-width: 600px) {
  .page-nav h2 {
    font-size: 26px;
  }
}

.btn-login {
  color: #ffffff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  background-color: #87b7e9;
  font-weight: 700 !important;
  font-size: 1rem !important;
}

.form-control:focus {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border: 0px;
}

.collapse.show {
  display: block !important;
}

.form-control {
  background-color: #f8f8f8;
  margin-bottom: 20px;
}

/* .containerLogin {
  max-width: 1170px;
} */

/* ===================================== Login Page Design CSS ==================================== */

@media screen and (max-width: 991px) {
  .form-login-body {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (max-width: 480px) {
  .form-login-body {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.form-login-body .login-desk {
  filter: drop-shadow(0 13.5px 13.5px rgba(0, 0, 0, 0.129));
  margin-top: 5%;
  /* margin-bottom: 5%; */
  border-radius: 10px !important;
}
.form-login-body .login-desk .detail-box {
  background-color: #87b7e9;
  padding: 20px;
}
.form-login-body .login-desk .detail-box .logo {
  width: 130px;
}
.form-login-body .login-desk .detail-box .help {
  width: 450px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.form-login-body .login-desk .detail-box .detailsh {
  text-align: center;
  font-weight: 700 !important;
  color: #fff !important;
}
.detail-box {
  background-color: #87b7e9;
}
.welcome {
  font-weight: 700 !important;
}
.loginform-body {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.form-login-body .login-desk .loginform {
  background-color: #f0f2fa !important;
  text-align: center !important;
  color: #fff !important;
  padding: 20px !important;
  padding-top: 100px !important;
}
.form-login-body .login-desk .loginform a {
  color: #000 !important;
}
.form-login-body .login-desk .loginform h2 {
  color: #000 !important;
  font-size: 1.8rem;
}
.form-login-body .login-desk .loginform p {
  font-size: 1rem;
  margin-bottom: 50px;
}
.form-login-body .login-desk .loginform .login-det .form-row {
  margin-top: 10px;
}
.form-login-body .login-desk .loginform .login-det .form-row label {
  color: #000 !important;
  font-size: 1rem;
}
.form-login-body
  .login-desk
  .loginform
  .login-det
  .input-group
  .input-group-prepend
  .input-group-text {
  background-color: #3d46a7;
  color: #ffffffcf;
  border-right: 0px;
  border: 0px;
}
.form-login-body .login-desk .loginform .login-det .input-group .form-control {
  background-color: #fff;
  color: #000;
  border-left: 0px;
  border: 0px;
  border-radius: 25px;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.form-login-body
  .login-desk
  .loginform
  .login-det
  .input-group
  .form-control:focus {
  border: 0px;
}

.form-login-body .login-desk .loginform .forget {
  text-align: right;
}
.form-login-body .login-desk .loginform .social-link ul {
  float: right;
  margin-top: 30px;
}
.form-login-body .login-desk .loginform .social-link ul li {
  float: left;
  padding: 15px;
  font-size: 0.9rem;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #787777 !important;
  font-size: 1rem !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff !important;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #fff !important;
}

/* ===================================== Responsive Style CSS ==================================== */
@media screen and (max-width: 991px) {
  .we-are-making .text-part {
    text-align: center;
  }

  .we-are-making .text-part .count .count-sing {
    margin-bottom: 20px;
  }

  .headwe-nav .containerLogin {
    padding: 10px;
  }

  header .headwe-nav .nav-div .navbar-nav .nav-item .nav-link {
    padding: 10px;
    border-right: 0px;
  }
}
