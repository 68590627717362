#containerDiv{

height: 100vh;
background: #ffffff;
display: flex;
align-items: center;
justify-content: center;
}
.text{
    font-size: 10px;
}
#grid{
    margin-top: 0;
    position: absolute;
    top: 20%;
    border-radius: 0.5rem;
    box-shadow: 0 0 1rem #395a61;
    max-width : 25rem;
    max-height : 30rem
}
#imageDiv{
    height: 8rem;
    width: 8rem;
    border : 1px solid #fff;
    border-radius: 50%;
    -moz-box-shadow: 0 0 1rem #f1bedf;
    -webkit-box-shadow: 0 0 1rem #e1a7cd;
    box-shadow: 0 0 1rem #604356;
    margin : auto;
    position: relative;
}
#profileImage{
    border-radius: 50%;
    opacity : 1;
    object-fit: cover;
    height: 100%;
    width: 100%;
    transition: .5s ease;
    backface-visibility: hidden;
    margin : auto;
    display : block;
}

#imageDiv button{
    position: absolute;
    bottom : 0;
    right : 0;
}
.middle{
transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
#imageDiv:hover #profileImage{
    opacity : 0.3
}

#imageDiv:hover .middle{
    opacity : 1
}

#name{
    font-size : 2rem;
    padding : 0;
    font-family : system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}
#username{
font-size : 1rem;
    padding : 0;
    font-family :'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.userDetails{
    
}
.row{
    margin : 0 !important;
    padding : 0 !important;
}

.row div{
    padding: auto 4rem;
    margin : 0 !important;
}
.row div h6, .row div p {
    padding-top: 5px;
}
#changePic{
    position : absolute;
    bottom: 0;
    right: 0;
    font-size:10px;
    color: rgb(238, 131, 131);
    
}

.pecilBtn{
    position: absolute;
    bottom : 0;
    right: 0
}