.logTypeGrid div{
    padding: 0 !important;
    margin: 0 !important;
}




.listGroup{
    min-height: 60vh;
    max-height: 60vh;
    overflow : auto;
}

.logDiv{
    min-height : 90vh;
    display : block;
}

.mainDiv{
    position : relative;
}

.addLog{
    position: absolute;
    bottom : 5%;
    left : 0;
    font-size: 10px;
    font-weight: 400;
    width: 100%;
    margin-left: 1rem;
}

.history{
    font-size: 0.8rem;
}

.listGroup p{
    font-size : 0.75rem;

}