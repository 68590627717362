
.container{
    background-color: rgb(255, 255, 255);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-55%, -55%);
    z-index: 100;
    min-width : 40%;
    padding : 2rem;
    border-radius : 1rem;
    max-height : 80vh;
}
.title{
    display: flex;
}

.title p {
    font-weight : 600;
    font-size : 1.5rem;

}
.templates{
    padding : 0.2rem;
     max-height: 60vh;
    overflow-y: scroll;
    overflow-x : hidden;
}
.content{
    cursor : pointer;
    max-height: 60vh;
    overflow-y: auto;
    overflow-x : hidden;
}
.content p{
    font-size : 1rem;
    font-weight : 500;
    padding : 10px;
}

.content2{
    border-radius: 5px;
    margin: 10px auto;
   display : grid;
   grid-template-columns: 97% 1fr;
   padding : 10px;
   box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.content2:hover{
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.templateTitle{
    font-size : 1.5rem;
    font-weight : 500;
    color: rgb(230, 94, 94);
}

.description{
    color : rgb(115, 103, 103)
}
.content p:hover{
    background-color :rgb(223, 207, 207)
}
.content span{
    padding-inline-end: 1rem;
}

.shareButton{
    width: fit-content;
    color: rgb(83, 83, 246);
    border-radius: 50%;
    padding : 5px;
    cursor : pointer;
    float : right;
}

.shareButton span{
    padding : 0;
    margin : auto;
}

/* whatsappImageTemplate */
.imageContainer img{
    height : 5rem;
    display : inline-flex;
    margin-inline-end : 1rem;
    margin:10px;
}

.imageContainer{
    display : grid;
    grid-template-columns: 1fr 10fr 1fr;
}


.table,.table th,.table td {
  border: 1px solid rgb(193, 188, 188);
  border-collapse: collapse;
}
