.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.pointer{
  cursor: pointer;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body {
  overflow: hidden;
}
body ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: #000 !important;
} */

.row {
  width: 100% !important;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.containerAdmin {
  display: flex;
  width: 100%;
  /* padding-bottom: 15px; */
}
.container-fluid {
  background-color: '#F0F2FA';
  min-height: 520px !important;
}

.container-fluid1 {
  background-color: '#F0F2FA';
  min-height: 400px !important;
}



main {
  transition: all 0.9s;
  width: 100%;
  background-color: #f0f2fa;
  /* min-height: 620px; */
  min-height : 100vh;
}

.iconAdmin,
.link_textAdmin {
  font-size: 20px;
}

.header.header-fixed {
  box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
  -webkit-box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
  background: #000;
  position: sticky !important;

  width: 100%;
  top: 0;
}

.sidebar {
  font-family: 'Prox' !important;
  background: rgb(0, 7, 61);
  color: white;
  height: 100vh;
  overflow-y: auto;
}

.menu {
  display: flex;
  color: white;
  font-size: 20px !important;
  padding: 0px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}
.menu_item {
  display: flex;
  color: #f1f3fa;
  padding: 10px 5px;
  margin: 10px 5px;
  gap: 15px;
}
.menu_container {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  background-color: #e6e7eb !important;
}
.menu_container .link {
  padding-left: 0px;
}

.sidebarAdmin {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  overflow-x: hidden;
  background-color: #fff !important;
  color: #000 !important;
  /* min-height: 630px; */
  min-height : 100vh;
  width: 200px;
  /* transition: all 0.5s; */
  font-family: 'Prox' !important;
  box-shadow: 0px 4px 4px rgba(244, 239, 239, 0.904);
}
.top_sectionAdmin {
  display: flex;
  align-items: center;
  padding: 3px;
}
.logoAdmin {
  font-size: 30px;
}
.barsAdmin {
  display: flex;
  font-size: 25px;
  margin-left: 10px;
}
.linkAdmin {
  display: flex;
  color: #000;
  padding-left: 15px;
  margin-top: 5px;
  gap: 5px;
  font-weight: 300 !important;
  font-size: 5px !important;
  /* transition: all 0.5s; */
  text-decoration: none !important;
}

.linkSubmenu {
  display: flex;
  color: #000;
  padding: 2px 15px;
  margin-left: 10px !important;
  /* margin: 5px 5px; */
  gap: 5px;
  text-decoration: none !important;
}

.linkSubmenu:hover {
  background: #f2f2f2 !important;
  color: #323332;
  transition: all 0.5s;
}

.active {
  background: #e0e1e1;
  color: #000;
  border-radius: 10px;
}

.mibar-brand.active {
  background: #fff;
}

.linkAdmin:hover {
  background: #dadada;
  color: #2f3334;
  transition: all 0.5s;
}

.navbar {
  font-family: 'Roboto', sans-serif;
  background-color: unset !important;
  background-image: linear-gradient(to left, #8360c3, #2ebf91) !important;
  color: #000 !important;
  border-radius: 0;
  box-shadow: none;
  border: none;
  /* position: fixed !important;
  top: 0;
  left: 0; */
  z-index: 1000;
  width: 100%;
  padding: 0;
}
.navbar .navbar-brand {
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}
.navbar .navbar-custom-right-menu {
  float: right;
}
.navbar .navbar-toggle {
  text-decoration: none;
  color: #fff;
  width: 20px;
  height: 20px;
  margin-top: -7px;
  line-height: 40px !important;
  /* margin-right: 17px; */
}
.navbar .navbar-toggle:before {
  content: '\e8d5';
  font-family: 'Material Icons';
  font-size: 26px;
}
.navbar .navbar-collapse.in {
  overflow: visible;
}
.navbar .nav.navbar-nav {
  display: block;
}

.navbar .dropdown-menu ul.menu li {
  width: 100%;
}
.navbar .nav-left-menu {
  margin: 3px 15px;
}
.navbar .collapse-menu-icon {
  margin-bottom: 0px;
  line-height: 60px;
  padding-left: 35px;
}

.navbar-brand {
  color: #000 !important;
  float: left;
  height: 45px;
  padding: 6px 0px;
  font-size: 24px !important;
  font-family: 'Roboto', sans-serif !important;
  line-height: 20px;
  text-align: left;
  width: 100%;
}

.navbar-logo {
  display: inline-block;
}

.navbar-logo:hover {
  color: #f0f2fa;
}

.navbar-brand img {
  /*float: left;*/
  vertical-align: top;
}
.navbar-brand span {
  line-height: 32px;
}
.navbar-collapse {
  padding-right: 15px;
  padding-left: 15px;
  overflow-x: visible;
  border-top: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.911);
}
.collapse {
  display: none;
}
.navbar-nav {
  margin: 0px -15px;
}
.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}
.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav > li {
  position: relative;
  display: block;
}
.nav > li > a {
  position: relative;
  display: block;
  padding: 5px 0px;
}
.nav .user-menu-icons {
  margin: 0px 5px;
}
.nav .user-menu-icons .feather {
  height: 18px;
  width: 18px;
  vertical-align: middle;
}
nav.navbar {
  min-height: 50px;
}

.navbar-header {
  padding: 8px;
  background-color: #fff;
  width: 265px;
  transition: all 0.5s;
}
.navbar-header .bars {
  float: left;
  text-decoration: none;
}
.navbar-icon {
  list-style-type: none;
  padding-left: 20px;
}
.logo-name {
  color: white;
  font-size: 24px;
  font-weight: 400;
}
.navbar-nav > li .js-right-sidebar {
  margin-right: 20px;
}
.navbar-nav.navbar-right {
  float: right;
  margin: 0px 10px;
  line-height: 40px;
  width: 30%;
}
.navbar-nav.navbar-right .mat-icon-button {
  width: 24px;
  height: 24px;
  margin: 0px 10px;
  line-height: 24px;
}
.navbar-nav.navbar-right .user_profile {
  margin: 0px 6px 0px 0px;
}

.navbar-nav.navbar-right .user_profile .user_img {
  float: right;

  border-radius: 50%;
}
.user_img {
  width: 34px;
  height: 34px;
}
.navbar-nav.navbar-right .user_profile span {
  font-weight: 500;
}
.navbar-nav .dropdown-menu {
  margin-top: 60px;
  width: 325px;
  right: 0;
  left: auto;
  position: absolute;
  top: 0;
  padding: 0;
  border-radius: 5px;
}
.navbar-nav .dropdown-menu::before {
  content: '';
  position: absolute;
  top: -7px;
  right: 19px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #0e2b49;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
}
.navbar-nav .dropdown-menu::after {
  content: '';
  position: absolute;
  top: -6px;
  right: 20px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #007bff;
  border-left: 6px solid transparent;
}
.navbar-nav .dropdown-menu ul.menu .menu-info p {
  line-height: 1;
}
.navbar-nav .dropdown-menu ul.menu .menu-info p .material-icons {
  display: inline-block;
}
.navbar-nav .dropdown-menu li.footer {
  width: 100%;
  height: 45px;
}
.navbar-nav .dropdown-menu .header {
  line-height: 2;
}
.navbar-nav .material-icons {
  line-height: 1;
  height: 24px;
  font-size: 27px;
}
.navbar-nav .fas {
  line-height: 1;
  height: 24px;
  font-size: 18px;
}
.navbar-nav .far {
  line-height: 1;
  height: 24px;
  font-size: 18px;
}
.navbar-nav .fab {
  line-height: 1;
  height: 24px;
  font-size: 18px;
}
.navbar-nav .fa {
  line-height: 1;
  height: 24px;
  font-size: 18px;
}
.navbar-nav .user_profile .user_dw_menu {
  list-style-type: none;
  padding-left: 0px;
}
.navbar-nav .user_profile .user_dw_menu li {
  width: 100%;
  border-bottom: 1px solid #eee;
  height: 45px;
}
.navbar-nav .user_profile .user_dw_menu li a {
  line-height: 27px;
  color: #333333;
  padding: 8px 15px;
  display: inline-flex;
}
.navbar-nav .user_profile .user_dw_menu li i {
  float: left;
  padding-right: 5px;
}
.navbar-nav .user_profile .user_dw_menu li:last-child {
  border-bottom: 0;
}
.navbar-nav .user_profile .dropdown-menu {
  width: 200px;
  right: -10px;
}

.content-body {
  width: 100%;
  /* overflow: hidden; */
}

.table-sel td {
  background-color: rgb(13, 124, 179) !important;
  color: #fff !important;
  font-weight: bold !important;
}

.table-details {
  /* background-color: rgb(254, 255, 254) !important; */
  color: #000 !important;
  font-weight: 600 !important;
}

/* .table-bordered td,
.table-bordered th {
  border: 1px solid #5b575a !important;
} */

table {
  font-family: 'Prox' !important;
  font-weight: 800px !important;
  font-size: 20px !important;
}
.table-full {
  /* padding: 5px !important; */
  background-color: #fff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  min-height: 500px;
}

.table-full-2 {
  /* padding: 5px !important; */
  background-color: #fff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  min-height: 75vh;
}

.table-full-3 {
  /* padding: 5px !important; */
  background-color: #fff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  min-height: 570px;
}

.chart-full {
  padding: 25px !important;
  background-color: #fff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.card-header.bg-secondary.text-white.row-sel {
  background-color: #fff !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.card-layout {
  box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
    0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1),
    0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
    0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
  border-radius: 20px;
  padding-top: 15px;
  font-size: 14px !important;
  max-width: 80% !important;
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
}

.container {
  padding-left: 0px !important;
}

.dashIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.edu_cat_2 {
  width: 100%;
  margin-bottom: 2px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  /* background: #f4f5f7; */
  padding: 5px 2px;
  display: flex;
  align-items: center;
  transition: all ease 0.4s;
  cursor: default !important;
}

/* .edu_cat_2.cat-1 {
  background: #fff;
} */

.edu_cat_2.cat-1 h4 > a {
  color: #000;
}

.edu_cat_icons1 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #87b6e8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edu_cat_icons2 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edu_cat_icons3 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #57e340;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edu_cat_icons4 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edu_cat_data {
  padding-left: 5px;
  cursor: default !important;
}

.edu_cat_data h4 {
  font-weight: 500 !important;
  font-size: 17px !important;
  margin-bottom: 2px !important;
  cursor: default !important;
}

.edu_cat_data.title h4 {
  cursor: default !important;
}

.edu_cat_data ul li i {
  margin-right: 0px !important;
}

.lead-fields {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.add-button {
  background: #1e9e09 !important;
  border-radius: 8px;
}

.card {
  min-height: 400px !important;
}

.card-body {
  padding: 3px !important;
  min-height: 400px !important;
  max-height: 500px !important;
  overflow: auto !important;
}

.actionBtn {
  cursor: pointer !important;
}

.containerLogin {
  max-height: 100% !important;
}
.modal.fade {
  height: 100% !important;
  width: 100% !important;
}

.loading {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: grid;
  place-items: center;
  max-width: 100%;
}

.loading > div {
  width: 10vmax;
  height: 10vmax;
  border-bottom: 5px solid red;
  border-radius: 50%;
  animation: loadingRotate 800ms linear infinite;
}

@keyframes loadingRotate {
  to {
    transform: rotateZ(-360deg);
  }
}

.ant-spin {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.fa-trash {
  color: #f25762;
}

.fa-edit {
  color: #0e5fb5;
}

.sticky-dash {
  position: -webkit-sticky;
  position: sticky;
  top: 4rem;
  z-index: 10;
  background-color: #f0f2fa !important;
}

.table-details td {
  padding: 0px !important;
  font-weight: 300 !important;
}

td {
  text-align: center !important;
}

.list-group-item {
  padding: 0rem 0rem !important;
}

h2 {
  margin-bottom: 0px !important;
}

h4 {
  margin-bottom: 0px !important;
}

.form-group {
  margin: 0px !important;
}

.dash.col {
  padding-right: 0px !important;
}

.MuiOutlinedInput-root {
  height: 45px !important;
}

.ant-message {
  z-index: 999999 !important;
}
.ant-alert {
  z-index: 999999 !important;
}

/* tr:nth-child(odd) {
  background-color: #e9f0ff !important;
} */



.viewopp .row {
  margin: 0px !important;
}

.tbody3 {
  display: block !important;
  max-height: 410px !important;
  overflow-y: scroll !important;
}

.tbody2{
  display: block;
  overflow:auto;
  height: 70vh;
}
.tbody2 {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.tbody2::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.tbody1 {
  display: block !important;
  max-height: 55vh !important;
}

tbody tr td {
  font-size: 15px !important;
}

td {
  min-width: 0;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

thead,
tbody tr {
  display: table !important;
  width: 100% !important;
  table-layout: fixed !important;
}

thead td {
  padding: 2px !important;
}

.fitTd {
  max-width: 1px !important;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  height: 1px !important;
  background: #f0f2fa;
}

td.ASC::after {
  content: '👇';
  display: inline-block;
  margin-left: 1em;
}

td.DSC::after {
  content: '☝️';
  display: inline-block;
  margin-left: 1em;
}

.table-full-2 .MuiFormControl-root {
  height: 33px !important;
}

.table-full-2{
  height: 33px !important;
}

.table-full-2
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px !important;
}

.table-full-2 .css-qiwgdb {
  padding: 0px !important;
}

.table-full-3 .MuiFormControl-root {
  height: 33px !important;
}

.table-full-3 .MuiSelect-select {
  height: 33px !important;
}

.table-full-3
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px !important;
}

.table-full-3 .css-qiwgdb {
  padding: 0px !important;
}
.search-dash .MuiOutlinedInput-root {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #fff !important;
}

table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
table tbody {
  display: table;
  width: 100%;
}

.addLog .col-3 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.addLog .col-5 {
  padding: 0px !important;
}
.addLog .col-4 {
  padding: 0px !important;
}

.addLog {
  max-width: 700px !important;
}

.table-full-2 .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #000 !important;
}
.table-full-2 .css-igs3ac {
  border-color: #000 !important;
}

.table-full-2 .css-j204z7-MuiFormControlLabel-root {
  margin: 0px !important;
}

.table-full-2 .css-q8hpuo-MuiFormControl-root {
  padding-left: 10px !important;
}

.table-full-2 .css-1vovazs {
  padding-left: 10px !important;
}

.table-full-2 .css-1jaw3da {
  margin: 0px !important;
}
.profile-card {
  height: 390px !important;
  width: 390px !important;
  background-color: #fff;
  border-radius: 10px;
  border: none;
  position: relative;
  margin-bottom: 30px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
    0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1),
    0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
    0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
}

.profile-header {
  min-height: 150px;
  color: #fff;
}

.user-name {
  padding: 3px;
  font-size: 22px;
  text-align: center;
  padding-top: 10px;
}
.user-img {
  border-radius: 10px;
  max-width: 112px;
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
}
.contact-grid {
  float: left;
  width: 100%;
  text-align: center;
}

/* template css */

.ui.grid {
  margin: 0px;
  padding: 0px;
}

.ui.grid .row {
  padding-top: 8px;
  padding-bottom: 8px;
}

.template .container {
  padding-right: 0px;
}

.jodit-status-bar {
  visibility: collapse;
}

.jodit-wysiwyg {
  max-height: 150px;
  background-color: #fff;
  overflow: scroll;
  padding-bottom: 0px;
}


/* permission field in update user page */
.permissionInUpdate .css-bac7y1{
  overflow-y: hidden;
}

/* Promotion Start */

.flexingProperty > a {
  border: 0.5px solid gray;
  text-align: center;
  padding: 5px 10px;
  margin-top: 5px;
  width: 500px;
}

.flexingProperty > a:hover {
  background-color: lightgreen;
}

.templateGroups > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.templateGroups > div > h5 {
  padding: 2px;
  font-weight: bolder;
  color: red;
}

.templateGroups > div > button {
border: none;
background-color: transparent;
}

.templateGroups > p:last-child {
  border-bottom: 1px solid black;
  margin-bottom: 10px;
}

.templateGroups > a {
  border-bottom: 1px solid black;
  margin-bottom: 10px;
}

.templateGroups-2  {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
  border-bottom: 1px solid black;
}

.templateGroups-2 > div:first-child {
  height: 150px;
  width: 150px;
}

.templateGroups-2 > div:first-child > img {
  height: 100%;
}

.templateGroups-2 > div:last-child > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.templateGroups-2 > div:last-child > div > h5 {
  padding: 2px;
  font-weight: bolder;
  background-color: rgb(228, 225, 225);
}

.templateGroups-2 > div:last-child > div button {
  padding: 2px 10px;
  border: 0.5px solid gray;
}

.modal-content {
  max-height: 700px !important;
}

.modal-body {
  max-height: 525px !important;
  overflow: auto !important;
}

.MuiDialog-paperWidthSm {
  max-width: 1400px !important;
}

/* Promotion End */

.onlyPaddingLR7px{
padding-left : 7px !important;
padding-right : 7px !important;
}


.dot{
  height: 10px;
  width: 10px;
  background-color: rgb(17, 100, 168);
  border-radius: 50%;
  display: inline-block;
  margin-inline-end: 5px;
}