.container {
    background-color: rgb(250, 250, 250);
    height: calc(100vh - 5rem);
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 5px;
    padding: 1rem;
}

.raiseTicket {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 49%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
}
.raiseTicket .header {
    margin : 2rem 0;
}
.raiseTicket h2{
    text-align: center
}


.raiseTicket .forms{
    display: flex;
    flex-direction: column;
    gap : 10px;
    width: 100%;
}
.screenshots div, .submitBtn{
    margin : 10px auto;
    display: flex;
    align-items: center;
    gap : 10px;
}


.ticketHistory {
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 49%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding : 10px;
}

.ticketHistory h2{
    text-align: center;
}
.issueDiv
{
    width: 100%;
    overflow-y: scroll;
}

.issueDiv:first-child{
    padding-top : 20px
}

.issueContainer{
    border-radius: 4px;
    padding: 10px;
    margin : 2px 5px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

}

.issueContainer div{
    position: relative;

}

.issueContainer h6{
    color : rgb(105, 0, 224);
    margin : 0;
    padding: 0;
}
.date{
    position: absolute;
    top: 0;
    right: 0;
    color : rgb(2, 4, 124);
}

.issue{
    color :rgb(68, 66, 66)
}

.nothingToShow{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90%;
    font-size: 2rem;
    font-family: 'Courier New', Courier, monospace;
}
.nothingToShow p{
text-align: center;
color:rgb(162, 169, 175)
}