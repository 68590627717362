.index{
    width: 3rem;
}

.checkbox{
    width: 1rem;
}

.AssignTo{
    width: 6rem;

}
.source{
    width:8rem;
    text-align: left !important;

}
.city{
    width: 6.5rem;
}
.status{
    width: 100px;
}
.name{
    width:10rem;
    text-align: left !important;
    padding-left: 5px !important;


}
.mob{
    width:90px;
}
.course{
    width:150px;
    text-align: left !important;

}
.price{
    width: 80px;
    text-align: right !important;
}
.days{
    width:4rem;
}

.followup{
    width: 80px;
}
.action{
    width: 60px;
}

.inputCheckbox{
    margin: 0px 1px;
}

@media only screen and (max-width: 1400px) {
    #dashboardTable thead td{
    font-size : 0.8rem;
    }


    #dashboardTable tbody tr td
    {
        font-size : 0.9rem !important;
    }
    .index{
    width: 2rem;
    }

    .AssignTo{
        width: 4rem;

    }
    .source{
        width:4rem
    }
    .city{
        width: 5.5rem;
    }
    .status{
        width: 70px;
    }
    .name{
        min-width:7rem;
        text-align: left !important;
        padding-left: 5px !important;
    }
    .mob{
        width:70px;
    }
    .course{
        width:100px;
        text-align: left !important;

    }
    .price{
        width: 50px;
        text-align: right !important;
    }
    .days{
        width:3.5rem;
    }

    .followup{
        width: 65px;
    }
    .action{
        width: 40px;
    }
}

#dashboardTable{
    overflow : auto !important;
}

#dashboardTable thead{
    position : sticky;
}

.dateRangePicker{
    padding : 0;
}

#dashboardTable tbody td{
    font-family: 'Inconsolata', monospace;
}

#dashboardTable thead td{
    text-align:center !important;
}

.padding7px{
    padding : 7px !important;
}

#clearFilter{
    position: absolute;
    top: 0;
    right : 0;
    padding : 5px 15px;
}
#clearFilter button{
    border-radius: 5;
    background-color: transparent;
    border: none
}


.dashboardTable tbody {
    display: block;
    height: 55vh;
    overflow: auto;
    -ms-overflow-style: none !important;  /* Internet Explorer 10+ */
    scrollbar-width: none !important;  /* Firefox */

}
.dashboardTable thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;/* even columns width , fix width of table too*/
}

.dashboardTable tbody::-webkit-scrollbar { 
    display: none !important;  /* Safari and Chrome */
}

.styling {
    max-width: 1500px !important;
}

.styling2 {
    width: 1500px !important;
    transform: translateX(-500px);
}