
.gridDiv {
  width: 100%;
}



.ui.container {
  margin: 0px;
  padding: 0px;
}

.template .container {
  padding-right: 0px;
}

.grid {
  border-radius: 5rem;
}

.tableContent{
  overflow: scroll;
  max-height: 75vh;
  padding: 0 !important;
  /* hide scroll bar */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.tableContent::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.status, .action, .title, .subject{
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.action{

}
.message{
  margin: auto 20px !important;

}
.gridRow{
  margin: 0 !important;
}